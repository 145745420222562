/**
 * Partnerform component
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import { CCol } from '@coreui/react-pro';
import CustomCheckbox from 'shared/components/CustomCheckbox';
import SimpleSearchabledropdown from 'shared/components/SimpleSearchabledropdown';
import { Link } from 'react-router-dom';
// Styles
import { CustomNewClientRow } from './styles';

const Partnerform = ({ t }) => {
  const {
    hasEditPermission,
    customerInfoWithPartner,
    partners,
    isPartnersLastPage,
    searchPartnerResults,
    searchPartnerText,
    partnerForm,
    partner,
    edit,
  } = useSelector((state: RootState) => state.BookingForm);

  const {
    setCustomerInfoWithPartner,
    setSearchPartnerResults,
    setSearchPartnerText,
    fetchPartners,
    onPartnerFormChange,
    setPartner,
    setPartnerMember,
  } = actions.BookingForm;

  const dispatch = useDispatch();

  const _renderSearchDropDown = () => {
    return (
      customerInfoWithPartner && (
        <CCol sm={6}>
          <SimpleSearchabledropdown
            id="bookingForm-searchPartner"
            placeholder={t('placeholders.partnersSearch')}
            options={searchPartnerText ? searchPartnerResults : partners}
            onSearch={(query) => dispatch(setSearchPartnerText(query))}
            parseResults={(r) => {
              return `${r.name}${r.shortName ? ` - ${r.shortName}` : ''}`;
            }}
            disabled={false}
            labelHelpText={
              partner && edit ? (
                <Link
                  style={{ fontSize: '14px', textDecoration: 'none' }}
                  to={`/entidades/editar-entidade/${partner.uuid}`}
                  target="_blank"
                >
                  {t('tripBookings.partnerDetails')}
                </Link>
              ) : (
                <></>
              )
            }
            isClearable={true}
            label={t('common.partner')}
            helpText={partnerForm.partner.message}
            valid={partnerForm.partner.valid}
            value={partner ? partner : partnerForm.partner.value}
            isLastPage={isPartnersLastPage}
            isSearchable={true}
            onSelectResult={(value) => {
              dispatch(
                onPartnerFormChange({
                  ...partnerForm,
                  partner: { value: value, message: '', valid: true },
                })
              );
            }}
            onClearResult={() => {
              dispatch(
                onPartnerFormChange({
                  ...partnerForm,
                  partner: { value: '', message: '', valid: true },
                })
              );
              dispatch(setSearchPartnerResults([]));
            }}
            onScrollBottom={() => dispatch(fetchPartners())}
          />
        </CCol>
      )
    );
  };

  return (
    <CustomNewClientRow>
      <CCol sm={6}>
        <CustomCheckbox
          id="bookingForm-withPartner"
          checked={customerInfoWithPartner}
          onClick={(e) => {
            dispatch(setCustomerInfoWithPartner(!customerInfoWithPartner));
            if (customerInfoWithPartner) {
              dispatch(
                onPartnerFormChange({
                  ...partnerForm,
                  partner: { value: '', message: '', valid: true },
                })
              );
              dispatch(setPartner(null));
              dispatch(setPartnerMember(null));
            }
          }}
          text={t('tripBookings.withPartner')}
          disabled={!hasEditPermission}
        />
      </CCol>
      {_renderSearchDropDown()}
    </CustomNewClientRow>
  );
};

export default Partnerform;
