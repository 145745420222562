/**
 * Searchabledropdown component
 *
 * @author Stephane Ribeiro <sgribeiro@ubiwhere.com>
 *
 *
 */
import React, { useState, ReactElement, useEffect } from 'react';
import { CFormLabel } from '@coreui/react-pro';
import { components } from 'react-select';
import Select from 'react-select';
import theme from 'ui/theme';
import { CustomFormLabel, Wrapper, HelpText } from './styles';
import debounce from 'lodash/debounce';

interface PropTypes {
  isSearchable?: boolean;
  isLastPage: boolean;
  onScrollBottom: Function;
  onSelectResult: Function;
  parseResults: Function;
  isClearable?: boolean;
  onClearResult?: Function;
  shape?: 'pill' | 'rectangular';
  id?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  valid?: boolean;
  value?: any;
  placeholder?: string;
  helpText?: string;
  labelHelpText?: ReactElement;

  options: any[];
  onSearch: Function;
}

interface Labels {
  value: string;
  label: string;
}

const SimpleSearchabledropdown = (props: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Labels[]>([]);

  //* Format prop options and stores them in component state
  useEffect(() => {
    if (props.options.length > 0) {
      const formatedOptions = props.options.map((r) => ({
        value: r.uuid,
        label: props.parseResults(r),
      }));
      setOptions(formatedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options]);

  const defaultValue: Labels = props.value
    ? {
        value: props.value.uuid,
        label: props.parseResults(props.value),
      }
    : { value: '', label: '' };

  //* Callback to fetch more options when scroll to bottom
  const loadMoreOptions = () => {
    if (props.onScrollBottom && !props.isLastPage) {
      setIsLoading(true);
      props.onScrollBottom();
      setIsLoading(false);
    }
  };

  //* Callback to handle option click
  const handleOptionClick = (option) => {
    props.onSelectResult(option.value);
  };

  //* Handle input search
  const handleSearch = debounce((inputValue) => {
    props.onSearch(inputValue);
  }, 400);

  const handleChange = (value, actionMeta) => {
    if (actionMeta.action === 'clear' && !value && props.onClearResult) {
      props.onClearResult();
    }
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 10,
      fontSize: '14px',
      border: `solid 1px ${theme().gray200Color}`,
      borderRadius: '0 0 4px 4px',
      backgroundColor: `${theme().white}`,
      padding: `${theme().em(5, 14)} 0 0`,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: `${theme().em(8, 14)} ${theme().em(14, 14)}`,
      cursor: 'pointer',
      backgroundColor: state.isSelected ? `${theme().gray500Color}` : 'transparent',
      ':hover': {
        backgroundColor: `${theme().gray200Color}`,
      },
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: `${props.shape && props.shape === 'pill' ? '50px' : '0px'}`,
      borderColor: `${theme().gray200Color}`,
      height: '33px',
      minWidth: '270px',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      ':focus-within': {
        borderColor: '#321fdb40',
        outlineWidth: '0.1px',
        outlineColor: '#321fdb40',
        outlineStyle: 'solid',
        outlineOffset: '0px',
        boxShadow: '0 0 1px 5px #321fdb40',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px',
    }),
  };

  return (
    <Wrapper>
      {props.labelHelpText ? (
        <CustomFormLabel>
          <div>
            {props.label}
            <span>{!!props.required ? '*' : ''}</span>
          </div>
          {props.labelHelpText}
        </CustomFormLabel>
      ) : (
        props.label && (
          <CFormLabel>
            {props.label}
            <span>{!!props.required ? '*' : ''}</span>
          </CFormLabel>
        )
      )}
      <Select
        id={props.id}
        onInputChange={handleSearch}
        onChange={handleChange}
        placeholder={props.placeholder}
        options={options}
        isClearable={props.isClearable ? true : false}
        components={{ Option: (props) => <CustomOption {...props} onClick={handleOptionClick} /> }}
        isDisabled={props.disabled}
        styles={customStyles}
        isLoading={isLoading}
        defaultValue={props.value ? defaultValue : null}
        onMenuScrollToBottom={loadMoreOptions}
        isSearchable={props.isSearchable ? true : false}
      />
      {props.helpText && <HelpText valid={props.valid}>{props.helpText}</HelpText>}
    </Wrapper>
  );
};

const CustomOption = (props) => {
  const { onClick } = props;

  return (
    <div onClick={() => onClick(props)}>
      <components.Option {...props}>{props.label}</components.Option>
    </div>
  );
};

export default SimpleSearchabledropdown;
