/**
 * Tripbookings scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
// Store
import { actions, RootState } from 'store/rootSlices';
// Components
import FilterComponent from 'shared/components/FilterComponent';
import FilterDatePicker from 'shared/components/FilterDatePicker';
import CustomButton from 'shared/components/CustomButton';
import SearchBox from 'shared/components/SearchBox';
import TableContainer from 'shared/components/TableContainer';
import SorterComponent from 'shared/components/SorterComponent';
import Loadingcontainer from 'shared/components/Loadingcontainer';
import Bookinglisttablerow from './components/Bookinglisttablerow';
import Searchabledropdown from 'shared/components/Searchabledropdown';
import CustomCheckbox from 'shared/components/CustomCheckbox';
// Icons
import Boarding from 'assets/icons/login';
import Landing from 'assets/icons/logout';
import Map from 'assets/icons/map';
import Feed from 'assets/icons/feed';
import Print from 'assets/icons/print';
import Download from 'assets/icons/file_download';
// Types
import { TripBookingListItem } from 'types/trips/bookings';
// Utils & Configs
import config from 'config';
import { paginationCurrentPage, paginationElementsCount } from 'utils';
import { presetsLists } from 'shared/components/FilterDatePicker/utils';
import { hasPermission } from 'permissions';
// Styles
import { ControlsContainer, Controls1, Controls2 } from 'shared/genericStyledComponents';
import { Wrapper, Tab, TabsWrapper, PrintWrapper, CustomLink, DownloadButton } from './styles';
import theme from 'ui/theme';
import API from 'api';

const Tripbookings = ({ t, i18n }) => {
  const {
    bookings,
    loading,
    page,
    sort,
    totalBookings,
    tripDateView,
    activityFilter,
    departureFilter,
    arrivalFilter,
    statusFilter,
    searchTerm,
    hasDateParams,
    startDate,
    endDate,
    partners,
    isPartnersLastPage,
    exportLoading,
    hasIssuesFilter,
  } = useSelector((state: RootState) => state.TripBookings);

  const { allActivities, allLocals } = useSelector((state: RootState) => state.TripsConfiguration);

  const { permissions } = useSelector((state: RootState) => state.App);

  const {
    onMount,
    onUnmount,
    setPage,
    setSort,
    setTripDateView,
    setActivityFilter,
    setArrivalFilter,
    setStatusFilter,
    setDepartureFilter,
    setDatesInterval,
    setSearchTerm,
    getBookingTicket,
    getBookings,
    exportBookings,
    setPartnerFilter,
    fetchPartners,
    setSearchPartnerResults,
    setHasIssuesFilter,
  } = actions.TripBookings;

  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(onMount(history));
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, history]);

  const ticketLinkRef = useRef<HTMLAnchorElement>(null);

  const allStatus = [
    { shortName: 'pending', id: 'pending' },
    { shortName: 'toPay', id: 'to_pay' },
    { shortName: 'toRefund', id: 'to_refund' },
    { shortName: 'paidF', id: 'paid' },
    { shortName: 'refundedF', id: 'refunded' },
    { shortName: 'canceled', id: 'canceled' },
  ];

  return (
    <Wrapper>
      <ControlsContainer>
        <Controls1>
          <SearchBox
            id="tripsConfig-searchBox"
            defaultValue={searchTerm}
            onSubmit={(term) => {
              if (term !== '') {
                let e = {
                  endDate: null,
                  startDate: null,
                };
                dispatch(setDatesInterval({ e, history }));
              } else {
                let e = {
                  endDate: new Date(),
                  startDate: new Date(),
                };
                dispatch(setDatesInterval({ e, history }));
              }
              let params = new URLSearchParams(history.location.search);
              term !== '' ? params.set('search', term) : params.delete('search');
              history.replace({ search: params.toString() });
              dispatch(setSearchTerm(term));
            }}
          />
          <FilterDatePicker
            presets={presetsLists().bookings}
            filterName={i18n.t('common.today')}
            range
            showMonthYearDropdown
            reRender={hasDateParams}
            noDate={startDate === '' && endDate === '' ? true : false}
            defaultValue={{
              startDay: startDate
                ? DateTime.fromISO(startDate).toJSDate()
                : searchTerm
                ? null
                : new Date(),
              endDay: endDate
                ? DateTime.fromISO(endDate).toJSDate()
                : searchTerm
                ? null
                : new Date(),
            }}
            onChange={(e) => {
              dispatch(setDatesInterval({ e, history }));
              dispatch(getBookings());
            }}
          />
          <FilterComponent
            id="tripBookings-activityFilter"
            filterName={i18n.t('tripPlanningForm.activity')}
            defaultOption={{
              label: allActivities.find((ct) => ct.id.toString() === activityFilter)
                ?.shortName as string,
              value: activityFilter,
            }}
            options={allActivities.map((a) => {
              return {
                label: a.shortName,
                value: a.id.toString(),
              };
            })}
            icon={<Map />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== '' ? params.set('activity', e.value) : params.delete('activity');
              history.replace({ search: params.toString() });
              dispatch(setActivityFilter(e.value));
            }}
          />
          <FilterComponent
            id="tripBookings-departureFilter"
            filterName={i18n.t('tripBookings.departure')}
            defaultOption={{
              label: allLocals.find((ct) => ct.id.toString() === departureFilter)
                ?.shortName as string,
              value: departureFilter,
            }}
            options={allLocals.map((l) => {
              return {
                label: l.name,
                value: l.id.toString(),
              };
            })}
            icon={<Boarding />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== '' ? params.set('departure', e.value) : params.delete('departure');
              history.replace({ search: params.toString() });
              dispatch(setDepartureFilter(e.value));
            }}
          />
          <FilterComponent
            id="tripBookings-arrivalFilter"
            filterName={i18n.t('tripBookings.arrival')}
            defaultOption={{
              label: allLocals.find((ct) => ct.id.toString() === arrivalFilter)
                ?.shortName as string,
              value: arrivalFilter,
            }}
            options={allLocals.map((l) => {
              return {
                label: l.name,
                value: l.id.toString(),
              };
            })}
            icon={<Landing />}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== '' ? params.set('arrival', e.value) : params.delete('arrival');
              history.replace({ search: params.toString() });
              dispatch(setArrivalFilter(e.value));
            }}
          />
          <FilterComponent
            id="tripBookings-statusFilter"
            filterName={i18n.t('tripBookings.status')}
            defaultOption={{
              label: i18n.t(
                `tripBookings.${
                  allStatus.find((ct) => ct.id.toString() === statusFilter)?.shortName as string
                }`
              ),
              value: statusFilter,
            }}
            options={allStatus.map((l) => {
              return {
                label: i18n.t(`tripBookings.${l.shortName}`),
                value: l.id.toString(),
              };
            })}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== '' ? params.set('status', e.value) : params.delete('status');
              history.replace({ search: params.toString() });
              dispatch(setStatusFilter(e.value));
            }}
          />
          <Searchabledropdown
            id="bookingForm-searchCustomer"
            placeholder={t('placeholders.partnersSearch')}
            initialResults={partners}
            loadOptions={async (value) => {
              const data = await API.Entities.GetEntities(1, value, {
                direction: null,
                field: null,
              });
              if (data.results && data.results.length > 0) {
                dispatch(setSearchPartnerResults(data.results));
              } else {
                dispatch(setPartnerFilter(''));
                dispatch(getBookings());
              }

              return data.results;
            }}
            parseResults={(r) => {
              return `${r.name}${r.shortName ? ` - ${r.shortName}` : ''}`;
            }}
            disabled={false}
            isLastPage={isPartnersLastPage}
            shape="pill"
            isClearable
            isSearchable
            onSelectResult={(value) => {
              let params = new URLSearchParams(history.location.search);
              value !== '' ? params.set('partner', value) : params.delete('partner');
              history.replace({ search: params.toString() });
              dispatch(setPartnerFilter(value));
            }}
            onClearResult={() => {
              let params = new URLSearchParams(history.location.search);
              params.delete('partner');
              history.replace({ search: params.toString() });
              dispatch(setPartnerFilter(''));
              dispatch(setSearchPartnerResults([]));
            }}
            onScrollBottom={() => dispatch(fetchPartners())}
          />
        </Controls1>
        <Controls2>
          <DownloadButton
            onClick={() => {
              if (!exportLoading) dispatch(exportBookings());
            }}
          >
            <Download width={30} height={30} />
          </DownloadButton>
          {hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.CREATE_BOOKING) && (
            <CustomButton
              text={i18n.t('tripBookings.newBooking')}
              icon="add"
              color="success"
              buttonStyle={{ color: theme().white }}
              onClick={(e) => {
                dispatch(actions.App.navigateTo(`/viagens/reservas/nova-reserva`));
              }}
            />
          )}
        </Controls2>
      </ControlsContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <TabsWrapper
          id="tripBookings-tabButton"
          size="sm"
          role="group"
          aria-label="Basic checkbox toggle button group"
        >
          <Tab
            checked={tripDateView}
            type="radio"
            onClick={(e) => {
              dispatch(setTripDateView(true));
            }}
            button={{ color: 'secondary', variant: 'outline' }}
            name="btnradio"
            id="bookings-tab-trip-date"
            label={i18n.t('tripBookings.tripDateView')}
          />
          <Tab
            checked={!tripDateView}
            type="radio"
            onClick={(e) => {
              dispatch(setTripDateView(false));
            }}
            name="btnradio"
            button={{ color: 'secondary', variant: 'outline' }}
            id="bookings-tab-created-date"
            label={i18n.t('tripBookings.createdDateView')}
          />
        </TabsWrapper>
        <CustomCheckbox
          wrapperStyle={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: '20px',
            flexGrow: 2,
            zIndex: '1',
          }}
          id="tripPlannings-isFinished"
          checked={hasIssuesFilter}
          onClick={(e) => {
            let params = new URLSearchParams(history.location.search);
            e.value !== ''
              ? params.set('hasIssues', (!hasIssuesFilter).toString())
              : params.delete('hasIssues');
            history.replace({ search: params.toString() });
            dispatch(setHasIssuesFilter(!hasIssuesFilter));
          }}
          text={i18n.t('tripBookings.hasIssues')}
          switch
        />
      </div>
      {loading ? (
        <Loadingcontainer />
      ) : (
        <TableContainer
          count={paginationElementsCount(totalBookings)}
          currentPage={paginationCurrentPage(totalBookings, page)}
          elementsPerPage={ELEMENTS_PER_PAGE}
          onPageChange={(pg) => {
            dispatch(setPage(pg));
          }}
          headerElements={[
            <SorterComponent
              sort={sort}
              id="tripBookings-numberSorter"
              filterName={t('tripBookings.bookingNumber')}
              active={sort.field === 'uid'}
              onChange={(dir) => {
                dispatch(setSort({ field: 'uid', direction: dir }));
              }}
            />,
            tripDateView ? (
              <SorterComponent
                sort={sort}
                id="tripBookings-dateSorter"
                filterName={t('common.date')}
                active={sort.field === 'trip_date'}
                onChange={(dir) => {
                  dispatch(setSort({ field: 'trip_date', direction: dir }));
                }}
              />
            ) : (
              t('tripBookings.acquisitionDate')
            ),
            <SorterComponent
              sort={sort}
              id="tripBookings-customerSorter"
              filterName={t('tripBookings.customer')}
              active={sort.field === 'customer'}
              onChange={(dir) => {
                dispatch(setSort({ field: 'customer', direction: dir }));
              }}
            />,
            t('tripBookings.service'),
            'PAX',
            t('tripBookings.extras'),
            t('tripBookings.value'),
            t('tripBookings.status'),
            t('common.actions'),
          ]}
        >
          {bookings.map((el: TripBookingListItem, idx: number) => (
            <Bookinglisttablerow
              key={`booking-${idx}`}
              id={`bookings-tableRow-${idx}`}
              booking={el}
              onClick={() => {
                if (hasPermission(permissions, PERMISSIONS.TRIPS.BOOKINGS.VIEW_BOOKING)) {
                  dispatch(actions.App.navigateTo(`/viagens/reservas/editar-reserva/${el.id}`));
                }
              }}
              actions={
                <React.Fragment>
                  {!el.ticket ? (
                    el.state === 'canceled' || el.state === 'pending' ? (
                      <CustomLink style={{ cursor: 'default' }}>
                        <Print fill={`${theme().gray300Color}`} />
                      </CustomLink>
                    ) : (
                      <PrintWrapper
                        onClick={() => dispatch(getBookingTicket({ id: el.id, ticketLinkRef }))}
                      >
                        <Print />
                      </PrintWrapper>
                    )
                  ) : (
                    <CustomLink
                      target="_blank"
                      ref={ticketLinkRef}
                      rel="noopener noreferrer"
                      href={`${el.ticket}`}
                    >
                      <Print />
                    </CustomLink>
                  )}
                  <Feed fill={`${theme().secondaryColor}`} />
                </React.Fragment>
              }
            />
          ))}
        </TableContainer>
      )}
    </Wrapper>
  );
};

export default Tripbookings;
