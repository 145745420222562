/**
 * CollaboratorsManagement scene
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Wrapper, ControlsContainer } from 'shared/genericStyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from 'store/rootSlices';
import SearchBox from 'shared/components/SearchBox';
import CustomButton from 'shared/components/CustomButton';
import SorterComponent from 'shared/components/SorterComponent/index';
import TableContainer from 'shared/components/TableContainer';
import FilterComponent from 'shared/components/FilterComponent';
import UsersListTableRow from './components/UsersListTableRow';
import ManageAccounts from 'assets/icons/manage_accounts';
import Feed from 'assets/icons/feed';
import Schedule from 'assets/icons/schedule';
import Mail from 'assets/icons/mail';
import Loadingcontainer from 'shared/components/Loadingcontainer';
import { paginationCurrentPage, paginationElementsCount } from 'utils';
import config from 'config';
import { hasPermission } from 'permissions';
import theme from 'ui/theme';
import { CollaboratorListItem } from 'types/collaborators/collaborator';
import People from 'assets/icons/people';

const CollaboratorsManagement = ({ t, i18n }) => {
  const {
    collaboratorsList,
    loading,
    total,
    page,
    collaboratorTypes,
    teams,
    filter,
    groupFilter,
    searchTerm,
    sort,
  } = useSelector((state: RootState) => state.CollaboratorsManagement);

  const { permissions } = useSelector((state: RootState) => state.App);

  const { onMount, onUnmount, setSearchTerm, setPage, setFilter, setGroupFilter, setSort } =
    actions.CollaboratorsManagement;

  const { ELEMENTS_PER_PAGE, PERMISSIONS } = config;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(onMount(history));
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, history]);

  const handleNavigateToUserDetails = (uuid) => {
    dispatch(actions.App.navigateTo(`/colaboradores/utilizadores/editar-utilizador/${uuid}`));
  };

  return (
    <Wrapper>
      <ControlsContainer>
        <div>
          <SearchBox
            defaultValue={searchTerm}
            debounceValue={300}
            onSubmit={(term) => {
              console.log('🚀 ~ CollaboratorsManagement ~ term:', term);
              let params = new URLSearchParams(history.location.search);
              term !== '' ? params.set('search', term) : params.delete('search');
              history.replace({ search: params.toString() });
              dispatch(setSearchTerm(term));
            }}
          />
          <FilterComponent
            id="collaboratorsManagement-collaboratorFilter"
            icon={<ManageAccounts width={20} height={20} viewBox={'0 0 18 18'} />}
            defaultOption={{
              label: collaboratorTypes.find((ct) => ct.id.toString() === filter)?.name as string,
              value: filter,
            }}
            onChange={(e) => {
              let params = new URLSearchParams(history.location.search);
              e.value !== '' ? params.set('role', e.value) : params.delete('role');
              history.replace({ search: params.toString() });
              dispatch(setFilter(e.value));
            }}
            filterName={'Colaborador'}
            options={collaboratorTypes.map((ct) => {
              return {
                label: ct.name,
                value: ct.id.toString(),
              };
            })}
          />
          {hasPermission(permissions, PERMISSIONS.USERS.CREATE_TEAM_MEMBER) && (
            <FilterComponent
              id="collaboratorsManagement-groupFilter"
              icon={<People width={20} height={20} viewBox={'0 0 17 12'} />}
              defaultOption={{
                label: teams.find((ct) => ct.id.toString() === groupFilter)?.name as string,
                value: groupFilter,
              }}
              onChange={(e) => {
                let params = new URLSearchParams(history.location.search);
                e.value !== '' ? params.set('group', e.value) : params.delete('group');
                history.replace({ search: params.toString() });
                dispatch(setGroupFilter(e.value));
              }}
              filterName={'Equipa'}
              options={teams.map((ct) => {
                return {
                  label: ct.name,
                  value: ct.id.toString(),
                };
              })}
            />
          )}
        </div>
        <div>
          {hasPermission(permissions, PERMISSIONS.USERS.CREATE_TEAM_MEMBER) && (
            <CustomButton
              id="collaboratorsManagement-newButton"
              onClick={(e) => {
                dispatch(actions.App.navigateTo('/colaboradores/utilizadores/novo-utilizador'));
              }}
              text={i18n.t('common.new')}
              icon="add"
              color="success"
              buttonStyle={{ color: '#FFFFFF' }}
            />
          )}
        </div>
      </ControlsContainer>
      {loading ? (
        <Loadingcontainer />
      ) : (
        <TableContainer
          id={`collaboratorsManagement-table`}
          count={paginationElementsCount(total)}
          currentPage={paginationCurrentPage(total, page)}
          elementsPerPage={ELEMENTS_PER_PAGE}
          onPageChange={(pg) => {
            dispatch(setPage(pg));
          }}
          headerElements={[
            <SorterComponent
              sort={sort}
              id="collaboratorsManagement-clockSorter"
              icon={<ManageAccounts viewBox="0 0 14 14" fill={'#636f83'} />}
              filterName="avatar"
              active={sort.field === 'clockin'}
              onChange={(dir) => {
                dispatch(setSort({ field: 'clockin', direction: dir }));
              }}
            />,
            <SorterComponent
              id="collaboratorsManagement-nameSorter"
              active={sort.field === 'name'}
              filterName={i18n.t('users.collaborator')}
              onChange={(dir) => dispatch(setSort({ field: 'name', direction: dir }))}
              sort={sort}
            />,
            <SorterComponent
              id="collaboratorsManagement-profileSorter"
              active={sort.field === 'profile'}
              filterName={i18n.t('users.profile')}
              onChange={(dir) => dispatch(setSort({ field: 'profile', direction: dir }))}
              sort={sort}
            />,
            i18n.t('users.phone'),
            i18n.t('users.email'),
            i18n.t('common.actions'),
          ]}
        >
          {collaboratorsList.map((el: CollaboratorListItem, idx: number) => (
            <React.Fragment key={`colab-list-${idx}`}>
              <UsersListTableRow
                id={`collaboratorsManagement-tableRow-${idx}`}
                onClick={() => {
                  if (hasPermission(permissions, PERMISSIONS.USERS.VIEW_TEAM_MEMBER)) {
                    handleNavigateToUserDetails(el.uuid);
                  }
                }}
                permission={hasPermission(permissions, PERMISSIONS.USERS.VIEW_TEAM_MEMBER)}
                user={el}
                actions={
                  <React.Fragment>
                    <Feed
                      className="pointer"
                      onClick={() => {
                        dispatch(
                          actions.App.navigateTo(`/colaboradores/historico-edicoes/${el.uuid}`)
                        );
                      }}
                    />
                    <Schedule fill={`${theme().secondaryColor}`} />
                    <Mail fill={`${theme().secondaryColor}`} />
                  </React.Fragment>
                }
              />
            </React.Fragment>
          ))}
        </TableContainer>
      )}
    </Wrapper>
  );
};

export default CollaboratorsManagement;
