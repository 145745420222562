/**
 * Searchbox component
 *
 * @author João Dias <jdias@ubiwhere.com>
 *
 *
 */

import React, { useState, useCallback } from 'react';
import SearchIcon from 'assets/icons/search';
import { CFormInput } from '@coreui/react-pro';
import debounce from 'lodash/debounce';
import i18n from 'i18next';
import { Wrapper } from './styles';

interface PropTypes {
  id?: string;
  onSubmit: Function;
  defaultValue?: string;
  debounceValue?: number;
}

const SearchBox = ({ defaultValue, onSubmit, id, debounceValue = 800 }: PropTypes) => {
  const [searchTerm, setSearchTerm] = useState(defaultValue || '');

  const handleDebouncedChange = useCallback(
    debounce((value: string) => {
      onSubmit(value);
    }, debounceValue),
    [onSubmit]
  );

  // Handle the input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Immediately trigger submission if the input is cleared
    if (value === '') {
      onSubmit(value);
    } else {
      handleDebouncedChange(value);
    }
  };

  return (
    <Wrapper>
      <SearchIcon />
      <CFormInput
        id={id}
        value={searchTerm}
        onChange={handleChange}
        placeholder={i18n.t('common.searchBox')}
      />
    </Wrapper>
  );
};

export default SearchBox;
