import initialState from 'scenes/BookingForm/logic/initialState';
import type { InitialStateProps } from 'scenes/BookingForm/logic/initialState';

const CustomerFormReducers = {
  onCustomerFormChange: (state: InitialStateProps, { payload }) => {
    state.customer = payload;
  },
  setShowCustomerInfo: (state: InitialStateProps, { payload }) => {
    state.showCustomerInfo = payload;
  },
  setSearchCustomerText: (state: InitialStateProps, { payload }) => {
    state.searchCustomerText = payload;
  },
  setBookingWithClient: (state: InitialStateProps, { payload }) => {
    state.bookingWithClient = payload;
  },
  fetchCustomers: () => {},
  setTripInfoForm: (state: InitialStateProps, { payload }) => {
    state.tripInfoForm = {
      ...state.tripInfoForm,
      observations: {
        value: payload.observations || '',
        valid: true,
        message: '',
      },
      customerName: {
        value: payload.customerName || payload.name || '',
        valid: true,
        message: '',
      },
      nif: {
        value: payload.nif || '',
        valid: true,
        message: '',
      },
      lang: {
        value: payload.lang || '',
        valid: true,
        message: '',
      },
      phone: {
        value: payload.phone || '',
        valid: true,
        message: '',
      },
      email: {
        value: payload.email || '',
        valid: true,
        message: '',
      },
      country: {
        value: payload.country || '',
        valid: true,
        message: '',
      },
    };
  },
  setSearchCustomerResults: (state: InitialStateProps, { payload }) => {
    state.searchCustomerResults = payload;
  },
  populateInvoiceInfo: (state, { payload }) => {
    let customer = payload;
    let invoiceBaseForm = { ...initialState.invoicePaymentForm };

    Object.keys(customer).forEach((key) => {
      if (key in invoiceBaseForm) {
        invoiceBaseForm[key] = {
          ...invoiceBaseForm[key],
          value: customer[key],
        };
      }
    });

    state.invoicePaymentForm = invoiceBaseForm;
  },
};

export default CustomerFormReducers;
