import type { InitialStateProps } from 'scenes/BookingForm/logic/initialState';

const PartnerFormReducers = {
  setCustomerInfoWithPartner: (state: InitialStateProps, { payload }) => {
    state.customerInfoWithPartner = payload;
  },
  setSearchPartnerResults: (state: InitialStateProps, { payload }) => {
    state.searchPartnerResults = payload;
  },
  setSearchPartnerText: (state: InitialStateProps, { payload }) => {
    state.searchPartnerText = payload;
  },
  fetchPartners: () => {},
  onPartnerFormChange: (state: InitialStateProps, { payload }) => {
    state.partnerForm = payload;
  },
  setPartner: (state: InitialStateProps, { payload }) => {
    state.partner = payload;
  },
  setPartnerMember: (state, { payload }) => {
    state.partnerMember = payload;
  },
};

export default PartnerFormReducers;
