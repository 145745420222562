import { call, putResolve, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from 'i18next';
// Store
import { actions, RootState } from 'store/rootSlices';
import API from 'api';
// Types
import { CustomersList } from 'types/customers/customers';

export function* fetchCustomersSaga() {
  try {
    const { searchCustomerText, customersPage, customers } = yield select(
      (state: RootState) => state.BookingForm
    );

    const res: CustomersList = yield call(
      API.Customers.GetBookingCustomers,
      searchCustomerText,
      searchCustomerText ? 1 : customersPage
    );

    if (res && res.results.length > 0) {
      if (searchCustomerText) {
        yield putResolve(actions.BookingForm.setSearchCustomerResults(res.results));
      } else {
        yield putResolve(
          actions.BookingForm.setCustomers({ customers, newCustomers: res.results })
        );

        if (res.next) {
          yield putResolve(actions.BookingForm.setCustomersPage(customersPage + 1));
        } else {
          yield putResolve(actions.BookingForm.setIsCustomersLastPage(true));
        }
      }
    }
  } catch {
    toast.error(i18n.t('toasts.customersGetError'));
  }
}
